<template>
  <router-view />
</template>

<script>
export default {
  name: "weekly",
  data() {
    return {};
  },
  computed: {
    wizardType() {
      return this.$store.state.builder.startedWizardType;
    },
  },
  methods: {},
  beforeMount() {
    if (this.wizardType != "weekly") {
      this.$router.push({ name: "builder" });
    }
  },
  beforeDestroy() {
    this.$store.dispatch("setwizardType", "weekly");
    this.$store.dispatch("saveUnfinishedBuild");
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
